import React from 'react';
import './Projects.css'
import powerChess from '../pic/power-chess.png'
import teamUp from '../pic/TeamUp.png'
import bottomHat from '../pic/bottom-hat.png'
import motifSong from '../pic/motify-song.png'
import checkMark from '../pic/check-mark.png'

const Projects = () => {
  return (
    <div className='project'>
      <h2>&lt;Project&gt;</h2>
      <div className='project-wrap'><img src={powerChess} alt="Power-Chess-Image" />
        <div className='text'>
          <span className="project-name">*Power Chess*</span>
          <p> Created a multiplayer Chinese Chess web application
        that you can play with friends, randoms, or even solo!</p>
        <p><b>Tech stack:</b> Vite, Php, MySQL, Apache</p>
        </div>
      </div>
      <div className='project-wrap'><img src={teamUp} alt="Team-Up-Image" />
        <div className='text'>
        <a href="https://team-up-xi.vercel.app/" target="_blank" rel="noopener noreferrer" className="project-link">
          <span className="project-name">*Team Up*</span></a>
          <p> A gaming forum that displays the top 5 streamers of specific games that you choose.
          There is a level and ranking system called xp that correlates to the likes you have in total for your posts.
          You can also find other players that play the same games as you.</p>
          <p><b>Tech stack:</b> React, Twitch API, SQLite, Django, Vercel, Railway</p>
        </div>
      </div>
      <div className='project-wrap'><img src={motifSong} alt="Motif-Song-Image" />
        <div className='text'>
        <a href="https://motif-song.com/" target="_blank" rel="noopener noreferrer" className="project-link">
          <span className="project-name">*Motif Song*</span></a>
          <p>Created a song recommendation site using Spotify API data so that 
            users can search for songs/artists/genres they liked and get recommendations for them.
            Users can also save songs to listen to later and find out what songs/artists 
            are currently popular.</p>
          <p><b>Tech stack:</b> React, Node.js, Express, Nginx, PostgreSQL, AWS EC2, Spotify API</p>
        </div>
      </div>
      <div className='project-wrap'><img src={checkMark} alt="Check-Mark-Image" />
        <div className='text'>
        <a href="https://check-mark.org/register" target="_blank" rel="noopener noreferrer" className="project-link">
          <span className="project-name">*Check Mark*</span></a>
          <p>Created a todo list web application for users to create
            to do items that can be sorted by priority, week, or present date.
            </p>
          <p><b>Tech stack:</b> React, Django, SQLite, Nginx, AWS EC2, AWS CloudFront, 
          AWS S3, Gunicorn, Supervisor</p>
        </div>
      </div>
       <div className='project-wrap'><img src={bottomHat} alt="Bottom-Hat-Image" />
        <div className='text'>
          <span className="project-name">*Bottom Hat*</span>
          <p>A quiz creation/maker web application where you can make your own
          quizzes and time limit or you can answer other people's quiz questions.</p>
          <p><b>Tech stack:</b> Bootstrap, Flask-Python, Socket.io, Nginx, Docker, MongoDB</p>
        </div>
      </div>
    </div>
  );
}

export default Projects;
